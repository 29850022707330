@import "core";
.section.giftcodeform {
  padding: 1.5rem 2rem;
  border-top: .1rem solid $color-border;
  border-bottom: .1rem solid $color-border;

  .giftcodeform-title {
    display: block;
    margin-bottom: 1rem;
  }

  .giftcodeform-wrap {
    display: flex;
  }

  .giftcodeform-input {
    flex: 1 0 0;
    margin-right: 1rem;
  }

  .giftcodeform-submitbtn {
    height: auto;
  }

  .giftcodeform-error {
    color: $color-error;
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }

  .giftcodeform-sum {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

    .sum-message {
      strong {
        padding: .5rem;
        background-color: $color-bg;
        border-radius: $radius-small;
        display: inline-block;
        margin-left: .2em;
      }
    }
}