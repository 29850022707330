@import "core";
.page-checkout-payment {
	.payment-instructions {
		color: $color-text-light;
		display: block;
		margin-bottom: 2.5rem;
	}

	.payment-feedback {
		margin-bottom: 2rem;
		margin-top: -1.5rem;
	}

	.payment-walletinfo {
		background-color: $color-bg;
		box-shadow: $shadow-general;
		padding: 2rem;
		border-radius: $radius-general;
		display: flex;
		align-items: center;
		font-size: 1.6rem;
		margin: 0 3rem 2rem 0;

		@include media {
			display: block;
			margin-right: 0;
			padding: 2rem 1.5rem 1.5rem;
		}
	}

		.walletinfo-content {
			flex: 1 0 0;
		}

		.walletinfo-icon {
			font-size: 3rem;
			margin-right: 2rem;

			@include media {
				display: block;
				margin: 0 0 1.5rem;
			}
		}

		.walletinfo-title {
			font-size: 2rem;
			display: block;
			margin-bottom: 1rem;
		}

		.walletinfo-balance {
			display: block;
		}

			.balance-amount {
				@include font-bold;
			}

		.walletinfo-useinput {
			margin-top: 1rem;
			
			&.inputwrap {
				color: inherit;
			}

			&.type-checkbox .checkwrap input:not(:checked) + label > .label-check {
				background-color: $color-white;
			}
		}

		.walletinfo-description {
			background-color: $color-white;
			padding: 1.5rem;
			border-radius: $radius-general;
			color: $color-text-light;
			font-size: 1.3rem;
			line-height: 1.2;
			width: 35%;
			margin: 0 0 0 2rem;
			
			@include media {
				width: 100%;
				margin: 1.5rem 0 0;
			}
		}

	.payment-cardinfo {
		display: flex;
		align-items: flex-start;

		@include mediaMin {
			padding-right: 3rem;
		}
	}

		.cardinfo-fields {
			flex: 1 0 0;
			flex-wrap: wrap;
			display: flex;
			
			@include mediaMin {
				margin-right: 2rem;
			}
		}

			.fields-name {
				margin-bottom: 1rem;
				width: 100%;
			}

			.fields-cardnumber {
				margin-bottom: 1rem;
				width: 100%;
			}

			.fields-expiry {
				flex: 1 0 0;
				margin-right: 1rem;
			}

			.fields-cvv {
				flex: 1 0 0;
			}

		.cardinfo-preview {
			width: 33rem;
			height: 20rem;
			position: relative;
			background-color: $color-primary;
			border-radius: $radius-big;
			color: $color-white;
		}

			.preview-cardnumber {
				@include font-number;
				position: absolute;
				text-transform: uppercase;
				font-size: 2.2rem;
				letter-spacing: -.04em;
				left: 2.5rem;
				bottom: 5.6rem;
			}

			.preview-cardname {
				position: absolute;
				text-transform: uppercase;
				left: 2.5rem;
				bottom: 1.5rem;
				letter-spacing: .04em;
			}

			.preview-type {
				position: absolute;
				top: 2.1rem;
				right: 1.8rem;
				width: 4.3rem;
				height: 3.5rem;
			}

			.preview-expiry {
				@include font-number;
				font-size: 1.6rem;
				position: absolute;
				bottom: 3.7rem;
				left: 14rem;
			}

			.preview-chip {
				position: absolute;
				left: 2.5rem;
				bottom: 9.2rem;
				width: 3.1rem;
				height: 2.8rem;
			}

	.section.footer {
		padding-bottom: calc(13.5rem + var(--safe-area-insets-bottom, env(safe-area-inset-bottom)));
	}
}